<template>
    <div>
        <h1 class="page__title">Редактирование {{ pageTitle }}</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6">
                    <label for="form-title" class="form-label">Название <sup class="text-theme-6">*</sup></label>
                    <input
                        id="form-title"
                        v-model="form.title"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                        :class="{ 'form-input-error': v$.form.title.$errors.length }"
                        required
                    />
                </div>

                <div class="col-span-2 flex items-center pt-6 mb-5">
                    <input
                        id="form-published"
                        v-model="form.published"
                        class="show-code form-check-switch flex-none mr-0 ml-3"
                        type="checkbox"
                    />
                    <label for="form-published" class="form-check-label">Опубликовано</label>
                </div>

                <div class="col-span-6">
                    <label for="form-file" class="form-label">Файл<sup class="text-theme-6"> *</sup></label>

                    <FileUpload
                        id="create-profile-documents-file-upload"
                        :files="form.file"
                        :error="v$.form.file.$errors.length"
                        @on-files-change="handleFilesChange"
                        @on-files-remove="handleFilesRemove"
                    />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link to="#" @click.prevent="redirectToMainPage" class="btn btn-secondary w-24 mr-2 mb-2">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import FileUpload from '@/components/base/file-upload/FileUpload.vue';
import { formPreparation } from '@/utils/object-to-form-data';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'ProfileDocumentEdit',
    components: { Preloader, FileUpload },
    mixins: [errorResponse, pageMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                title: { required },
                file: { required },
            },
        };
    },
    data() {
        return {
            loading: false,
            form: {
                title: null,
                published: false,
                file: [],
            },
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true;

            await this.axios
                .get(`/profile-documents/${this.$route.params.id}/edit`)
                .then((res) => {
                    this.pageTitle = res.data.data.title;

                    for (const [key, val] of Object.entries(res.data.data)) {
                        this.form[key] = val;
                    }
                    this.form.file = [{ file: this.form.file }];
                    this.loading = false;
                })
                .catch((ex) => {
                    this.loading = false;
                    console.log('cant fetch content page categories: ' + ex);
                });
        },
        handleFilesChange(files) {
            this.form.file = files;
        },
        handleFilesRemove(files) {
            this.form.file = files;
        },
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            const form = formPreparation({ ...this.form, file: this.form.file[0].file }, { imagesAsObject: true });

            await this.axios
                .post('/profile-documents/' + this.$route.params.id, form, {
                    params: {
                        _method: 'patch',
                    },
                })
                .then((res) => {
                    this.fetchData();
                    this.form.delete_items = [];
                    this.loading = false;
                    if (flag) {
                        this.$notify('Запись сохранена');
                    } else {
                        // this.$router.push({ name: 'content-profile-documents' });
                        this.redirectToMainPage();
                    }
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        redirectToMainPage() {
            this._pMTransitionToEditPageWithQuery('content-profile-documents');
        },
    },
};
</script>

<style></style>
